// required modules
import Model from '@/models/Model'

/**
 * This class describes a PCertification model.
 *
 * @class PCertification
 */
export default class PCertificationModel extends Model {

  /**
   * Define the PCertification resource.
   *
   * @return {String} PCertification endpoint name.
   */
  static resource() {
    return 'certification'
  }
}