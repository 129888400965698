//
import PCertificationService from '@/services/api/Provider/PCertification'
import PEconomicActivityService from '@/services/api/Provider/PEconomicActivity'
import PProviderService from '@/services/api/Provider/PProvider'
import PSubcategoryService from '@/services/api/Provider/PSubcategory'
import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-provider',
  data() {
    return {
      propsSubmit:{
        color:'secondary',
        plain:false,
        text:false,
      },
      resetProps:{
        color:'primary',
        text:false,
      },
      busy: {
        activities: false,
        certifications: false,
        provider: false,
        services: false,
        subcategories: false,
      },
      dialogs: {
        create: false,
        delete: false,
        update: false,
      },
      pCertifications: [],
      pEconomicActivities: [],
      pProvider: {
        certificationsIds: null,
      },
      pProviderService: {
        name: '',
        description: '',
      },
      pProviderServiceSelected: {
        name: '',
        description: '',
      },
      pSubcategories: [],
    }
  },
  methods: {

    /**
     * 
     */
    async getCertifications() {
      this.busy.certifications = true
      await PCertificationService.getAll().then(res => {
        this.pCertifications = res.data
      })
      this.busy.certifications = false
    },

    /**
     * 
     */
    async getEconomicActivities() {
      this.busy.activities = true
      await PEconomicActivityService.getAll().then(res => {
        this.pEconomicActivities = res.data
      })
      this.busy.activities = false
    },

    // obtener sub-categorías
    async getSubcategories() {
      this.busy.subcategory = true
      await PSubcategoryService.getAllPaginateByCategoryId(this.pProvider.categoryId).then(res => {
        this.pSubcategories = res.data        
      })
      this.busy.subcategory = false
    },

    /**
     * 
     */
    async handleActivitiesSubmit() {
      this.busy.activities = true
      await PProviderService.activities(this.pProvider).then(this.handleSuccess).catch(this.handleError)
      this.busy.activities = false
    },

    /**
     * 
     */
    async handleCertificationsSubmit() {
      this.busy.certifications = true
      await PProviderService.certifications(this.pProvider).then(this.handleSuccess).catch(this.handleError)
      this.busy.certifications = false
    },

    /**
     * 
     */
    handleCreateSubmit() {
      this.pProvider.services.push(this.pProviderService)
      this.handleServicesSubmit()
      this.dialogs.create = false
    },

    /**
     * 
     */
    handleDeleteSubmit() {
      let index = this.pProvider.services.findIndex(service => {
        return service.id == this.pProviderServiceSelected.id
      })
      this.pProvider.services.splice(index, 1)
      this.handleServicesSubmit()
      this.dialogs.delete = false
    },

    /**
     * 
     */
    handleUpdateSubmit() {
      let index = this.pProvider.services.findIndex(service => {
        return service.id == this.pProviderServiceSelected.id
      })
      this.pProvider.services[index] = this.pProviderServiceSelected
      this.handleServicesSubmit()
      this.dialogs.update = false
    },

    /**
     * 
     */
    handleError(payload) {
      PProviderService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    async handleFormReset() {
      this.busy.provider = true
      this.busy.services = true
      this.pProvider = await PProviderService.getById(this.$provider.id)
      this.getSubcategories()
      this.busy.provider = false
      this.busy.services = false
    },

    /**
     * 
     */
    async handleServicesSubmit() {
      this.busy.services = true
      await PProviderService.services(this.pProvider).then(this.handleSuccess).catch(this.handleError)
      this.busy.services = false
    },

    /**
     * 
     */
    async handleSubcategoriesSubmit() {
      this.busy.subcategories = true
      await PProviderService.subcategories(this.pProvider).then(this.handleSuccess).catch(this.handleError)
      this.busy.subcategories = false
    },

    /**
     * 
     */
    handleSuccess(payload) {
      PProviderService.translate({ response: payload }).then(this.$toast.success)
    },
  },
  created() {
    this.handleFormReset()
    this.getCertifications()
    this.getEconomicActivities()
  },
  props: {

    /**
     * 
     */
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(3),
      ],
    },
    
    /**
     * 
     */
    descriptionRules: {
      type: Array,
      default: () => [
        RulesService.required,
      ],
    },
  },
}